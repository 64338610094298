@use 'src/assets/scss/core/mixins' as mix;

.c-tooltip {
  @include media(
    (
      1280: (
        margin-left: vw(10),
      ),
      md: (
        margin-left: 12px,
      ),
    )
  );

  @include mix.TextB2Regular;

  display: flex;
  height: 14px;
  margin-left: 10px;
  cursor: pointer;

  &__icon {
    width: 14px;
    height: 14px;

    path {
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      path {
        fill: #373e45;
      }
    }

    &-wrapper {
      display: flex;
    }
  }

  &__hint {
    @include media(
      (
        1280: (
          font-size: vw(14),
          line-height: vw(20),
          padding: vw(16),
          max-width: vw(272),
        ),
        md: (
          font-size: 14px,
          line-height: 20px,
          padding: 16px,
          max-width: 272px,
        ),
      )
    );

    position: absolute;
    z-index: 2;
    top: 25px;

    box-sizing: border-box;
    width: fit-content;
    max-width: 272px;
    margin: 0;
    padding: 16px;

    font-size: 14px;
    line-height: 20px;
    color: #e9e9ea;

    visibility: hidden;
    background: #222222;

    .--triangle {
      @include media(
        (
          xsm: (
            left: 125px,
          ),
        )
      );

      position: absolute;
      top: -4px;
      left: 10px;

      width: 0;
      height: 0;

      border-right: 7px solid transparent;
      border-bottom: 7px solid #222222;
      border-left: 7px solid transparent;
    }

    ul {
      @include media(
        (
          1280: (
            padding-left: vw(20),
          ),
          md: (
            padding-left: 20px,
          ),
        )
      );

      margin: 0;
      padding-left: 20px;

      li {
        @include media(
          (
            1280: (
              margin-bottom: vw(16),
            ),
            md: (
              margin-bottom: 8px,
            ),
          )
        );

        margin-bottom: 16px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
