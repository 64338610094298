.c-check-box {
  position: relative;

  display: flex;
  flex-wrap: wrap;

  width: 100%;

  text-align: left;

  &__field {
    cursor: pointer;

    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    &:checked {
      & ~ .c-check-box__mark-wrapper {
        .c-check-box__mark {
          background: #4d5359;
          box-shadow: inset 0 0 0 2px #4d5359;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__mark {
    position: relative;
    z-index: 1;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 24px;
    height: 24px;

    color: #d2d2d2;

    background: transparent;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px #4d5359;

    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &::before {
      content: '';

      display: block;

      width: 100%;
      height: 100%;

      opacity: 0;
      background: url('/svg/components/CheckBox/check.svg?v=1') no-repeat center
        center;
      background-size: 50%;

      transition: opacity 0.1s ease-in-out;
    }

    &-wrapper {
      position: relative;

      display: inline-grid;
      grid-gap: 16px;
      grid-template-columns: 24px 1fr;

      width: 100%;

      text-align: left;
    }
  }

  &__label {
    @include media(
      (
        xsm: (
          font-size: 14px,
          line-height: 20px,
        ),
      )
    );

    padding: 0 35px 0 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  &__error {
    position: absolute;
    top: -10px;
    right: 0;

    font-size: 13px;
    color: $error-color;
  }

  &.--error {
    &::before {
      content: '';

      position: absolute;
      top: 11px;
      right: 0;

      color: $error-color;
    }

    .c-check-box__mark {
      box-shadow: inset 0 0 0 2px $error-color;
    }
  }

  &__subtitle {
    margin: 0 0 32px 32px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgb(33 40 48 / 64%);
  }

  &.--disabled {
    .c-check-box__mark {
      color: rgb(33 40 48 / 24%);
    }

    .c-check-box__label {
      color: rgb(33 40 48 / 48%);
    }

    .c-check-box__subtitle {
      color: rgb(33 40 48 / 48%);
    }

    .c-check-box__field {
      cursor: auto;
    }

    .c-check-box__field:checked ~ .c-check-box__mark-wrapper {
      .c-check-box__mark {
        background: rgb(33 40 48 / 48%);
        box-shadow: inset 0 0 0 4px rgb(33 40 48 / 1%);
      }
    }
  }
}
