.c-form-user-agreement-check-box {
  position: relative;
  margin-bottom: 30px;
  color: $main-color;

  .link {
    @include media(
      (
        xsm: (
          font-size: 14px,
          line-height: 20px,
        ),
      )
    );

    display: block;

    margin-left: 40px;

    font-size: 16px;
    line-height: 24px;
    color: $main-color;
    text-decoration: underline;
    letter-spacing: 0.4px;

    transition: color 0.3s ease-in-out;

    &:hover {
      color: $green-color;
    }
  }

  &.--error {
    color: $error-color;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:hover {
      display: block;

      .c-form-user-agreement-check-box__text {
        display: block;
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 2;
    top: 25px;
    left: 0;

    display: none;

    box-sizing: border-box;
    padding: 32px;

    font-size: 14px;
    line-height: 20px;
    color: #fff;

    background: $main-color;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgb(0 0 0 / 15%);

    &:hover {
      display: block;
    }
  }

  &__icon {
    cursor: pointer;

    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;

    opacity: 0.5;

    fill: $main-color;

    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;

      ~ .c-form-user-agreement-check-box__text {
        display: block;
      }
    }
  }

  &_white {
    .c-form-user-agreement-check-box__icon {
      fill: #263238;
    }

    .c-form-user-agreement-check-box__text {
      background: #f9faf9;
    }
  }

  &_dark {
    .c-form-user-agreement-check-box__icon {
      fill: #a2a2a2;
    }

    .c-form-user-agreement-check-box__text {
      background: rgb(25 28 39 / 100%);
    }
  }
}
