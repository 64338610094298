body {
  min-width: 375px;
  margin: 0;
  padding: 0;

  font-family: $main-font, Helvetica, sans-serif !important;
  font-weight: 400;

  &.overflow {
    overflow: hidden;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}

.link-rest {
  color: #222;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #26d07c;
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid #0063ff;
  }
}

#sp-root-container {
  z-index: 20 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
