.c-form-message-form-submitted {
  @include media(
    (
      md: (
        padding: 48px 24px,
      ),
    )
  );

  position: relative;
  z-index: 1;

  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  margin: 80px auto;
  padding: 103px 40px;

  text-align: center;

  background: #fff;
  border-radius: 4px;

  &::before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 7px;

    background: $green-color;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .c-button_primary {
    max-width: 320px;
    margin: 0 auto;
  }

  &-title {
    @include media(
      (
        xsm: (
          font-size: 35px,
          line-height: 39px,
        ),
      )
    );

    display: block;

    margin-bottom: 42px;

    font-size: 50px;
    line-height: 60px;
    color: $main-color;
  }

  &-text {
    @include media(
      (
        xsm: (
          font-size: 16px,
          line-height: 22px,
        ),
      )
    );

    display: block;

    margin-bottom: 40px;

    font-size: 20px;
    line-height: 24px;
    color: $main-color;
  }

  &_white {
    @include media(
      (
        550: (
          padding: 70px 20px,
        ),
      )
    );

    position: relative;
    z-index: 1;

    max-width: 535px;
    margin: auto;
    padding: 70px 90px;

    color: #263238;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: -1px 13px 20px 0 rgb(0 0 0 / 7%);

    .c-form-message-form-submitted-text {
      color: #2a2a2a;
    }
  }

  &_dark {
    @include media(
      (
        550: (
          padding: 70px 20px,
        ),
      )
    );

    position: relative;
    z-index: 1;

    max-width: 535px;
    margin: auto;
    padding: 70px 90px;

    color: #263238;

    background-color: #0a0c14;
    border-radius: 4px;
    box-shadow: 20px 20px 60px 0 rgb(44 57 96 / 10%),
      -20px -20px 60px 0 rgb(13 15 26 / 66%), inset 4px 4px 10px 0 #161926;
  }
}
