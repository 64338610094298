.c-select {
  user-select: none;

  display: flex;
  flex-direction: column;

  min-height: 40px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .c-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    color: #ffc700;

    &.open {
      .c-select__arrow {
        transform: rotate(-180deg);
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }

  &__title {
    color: $main-color;
  }

  &__required {
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    color: rgb(52 63 72 / 40%);
  }

  &__body {
    position: relative;

    display: flex;
    flex: 1;
    align-items: center;

    box-sizing: border-box;
    padding: 5px 16px;

    border: 1px solid #d2d2d2;
    border-radius: 2px;
  }

  &__error {
    margin-top: 5px;
    font-size: 13px;
    text-align: right;
  }

  &.--error {
    color: $error-color;

    .c-select__body {
      border: 1px solid $error-color !important;
    }
  }

  &__arrow {
    flex: none;
    margin-left: 10px;
    fill: $main-color;
    transition: transform 0.3s ease-in-out;
  }

  &__items {
    position: absolute;
    z-index: 3;
    top: 100%;
    left: -1px;

    overflow: auto;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: calc(100% + 2px);
    max-height: 170px;
    padding: 10px 30px 20px 16px;

    background: #fafafa;
    border: 1px solid #d2d2d2;
    border-top: none;
  }

  &__item {
    cursor: pointer;
    margin-bottom: 10px;
    color: $main-color;
    transition: color 0.3s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $green-color;
    }
  }
}
