.c-form-text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__name {
    margin: 0 5px 0 0;
    color: $main-color;
    transition: font-size 0.1s ease-in-out;
  }

  &__field {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding: 8px 16px;

    font-family: inherit;
    font-size: 16px;
    color: $main-color;

    background: transparent;
    border: 1.6px solid #d2d2d2;
    border-radius: 2px;

    transition: border 0.3s ease-in-out, color 0.3s ease-in-out;

    &::placeholder {
      color: $main-color;
      opacity: 0.4;
      transition: color 0.3s ease-in-out;
    }

    &:focus ~ .c-form-text-input__header,
    &:not(&:placeholder-shown) ~ .c-form-text-input__header {
      top: 10px;

      .c-form-text-input__name {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__required {
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    color: rgb(52 63 72 / 40%);
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;

    &_transition {
      pointer-events: none;

      position: absolute;
      top: 19px;
      left: 20px;

      transition: top 0.1s ease-in-out;
    }

    &_hint {
      position: relative;
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 13px;
    text-align: right;
  }

  &.--error {
    color: $error-color;

    .c-form-text-input__field {
      border: 1.6px solid $error-color !important;
    }
  }

  &__hint {
    position: absolute;
    top: 0;
    right: 0;

    width: 30px;
    height: 100%;

    &:hover {
      display: block;

      .c-form-text-input__text {
        display: block;
      }
    }

    &-wrapper {
      position: relative;
      z-index: 1;

      .c-form-text-input__field {
        padding: 8px 65px 8px 16px;
      }
    }

    &_label {
      .c-form-text-input__text {
        top: 22px;
        right: 3px;
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 4;
    top: 40px;
    right: 0;

    display: none;

    box-sizing: border-box;
    padding: 24px;

    font-size: 18px;
    line-height: 26px;
    color: #212830;

    background: #d3d4d6;
    border-radius: 8px;
    box-shadow: 0 0 8px rgb(45 60 100 / 3%),
      0 4px 32px rgb(45 60 100 / 5%);

    &:hover {
      display: block;
    }
  }

  &__icon {
    cursor: pointer;

    position: absolute;
    z-index: 3;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    opacity: 0.5;

    fill: $main-color;

    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;

      ~ .c-form-text-input__text {
        display: block;
      }
    }
  }

  &.--disabled {
    .c-form-text-input__name {
      color: rgb(33 40 48 / 24%);
    }

    .c-form-text-input__field {
      color: rgb(33 40 48 / 48%);

      &::placeholder {
        color: rgb(33 40 48 / 48%);
      }
    }
  }
}
