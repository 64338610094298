.p-calculator {
  margin-top: 56px;

  background: #fff;

  .c-constructor__hero_platform {
    background: #f0f0f0;
  }

  .c-calc-adv__menu,
  .c-calc-adv__service,
  .c-calc-adv__result {
    box-shadow: 0 0 8px rgb(45 60 100 / 3%), 0 4px 32px rgb(45 60 100 / 5%);
  }

  .c-constructor__hero-image-wrapper {
    @include media(
      (
        xlg: (
          max-width: vw(336),
        ),
        768: (
          max-width: 247px,
          align-self: flex-start,
        ),
        sm: (
          max-width: unset,
        ),
      )
    );

    max-width: 336px;
  }

  .c-constructor__hero-content {
    @include media(
      (
        768: (
          max-width: 404px,
        ),
        sm: (
          max-width: unset,
        ),
      )
    );

    max-width: 770px;
  }

  &__title {
    margin: 0 0 56px;
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
  }

  &-tariffs {
    &__wrapper {
      @include media(
        (
          xlg: (
            width: 100%,
            padding: 0 25px,
            box-sizing: border-box,
          ),
          md: (
            padding: 0,
            width: 696px,
          ),
          736: (
            width: $tablet-width-small,
          ),
          sm: (
            width: 100%,
            padding: 0 24px,
          ),
        )
      );

      width: $desktop-width;
      margin: 0 auto;
    }

    &__cards {
      @include media(
        (
          xlg: (
            padding: 0 0 vw(112),
          ),
          md: (
            grid-template-columns: 1fr,
            padding: 0 0 96px,
          ),
        )
      );

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      padding: 0 0 112px;
    }

    &__card {
      display: flex;
      flex-direction: column;

      padding: 32px 24px 24px 32px;

      background: #fff;
      border-radius: 24px;
      box-shadow: 0 0 8px rgb(45 60 100 / 3%), 0 4px 32px rgb(45 60 100 / 5%);

      &:hover {
        .p-calculator-tariffs__card-title {
          color: #06bd7b;
        }
      }

      &-title {
        @include media(
          (
            xlg: (
              font-size: vw(24),
              line-height: vw(28),
              margin-bottom: vw(16),
            ),
            md: (
              font-size: 24px,
              line-height: 28px,
              margin-bottom: 16px,
            ),
          )
        );

        margin-bottom: 16px;
        font-size: 24px;
        line-height: 28px;
        transition: color 0.3s ease-in-out;
      }

      &-description {
        font-size: 18px;
        line-height: 26px;
        color: #7a7e83;
      }
    }
  }

  &-advanced {
    .p-calculator__head {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 25px;
    }

    .p-calculator__title {
      @include media(
        (
          736: (
            width: $tablet-width-small,
          ),
          sm: (
            width: 100%,
          ),
        )
      );

      width: 650px;
      margin-top: 68px;
      margin-bottom: 32px;

      line-height: 56px;
      letter-spacing: -0.03em;
    }

    .p-calculator__subtitle {
      @include media(
        (
          736: (
            width: $tablet-width-small,
          ),
          sm: (
            width: 100%,
          ),
        )
      );

      width: 680px;
      margin-bottom: 68px;

      font-size: 24px;
      line-height: 32px;
      color: #7a7e83;
    }

    .c-calc-adv {
      @include media(
        (
          md: (
            padding: 0,
          ),
        )
      );
    }
  }

  .c-anchor-menu {
    position: static;
  }
}

.c-banner {
  padding: 44px 0 !important;

  @include media(
    (
      1280: (
        padding: '#{vw(44)} 0 !important',
      ),
      md: (
        padding: '0 !important',
      ),
    )
  );

  &__link {
    background: #fff !important;
  }
}
