.c-form-text-area {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__name {
    margin: 0 5px 0 0;
    color: $main-color;
    transition: font-size 0.1s ease-in-out;
  }

  &__field {
    resize: none;

    box-sizing: border-box;
    width: 100%;
    height: 147px;
    padding: 8px 16px;

    font-family: inherit;
    font-size: 16px;
    color: $main-color;

    background: transparent;
    border: 1.6px solid #d2d2d2;
    border-radius: 2px;

    &::placeholder {
      color: $main-color;
      opacity: 0.4;
    }

    &:focus ~ .c-form-text-area__header_transition,
    &:not(&:placeholder-shown) ~ .c-form-text-area__header_transition {
      top: 10px;

      .c-form-text-area__name {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__required {
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    color: rgb(52 63 72 / 40%);
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;

    &_transition {
      pointer-events: none;

      position: absolute;
      top: 11px;
      left: 20px;

      width: calc(100% - 30px);

      transition: top 0.1s ease-in-out;

      &::before {
        content: '';

        position: absolute;
        z-index: 1;
        top: -8px;
        left: 0;

        width: 100%;
        height: 28px;

        background: #e9e9ea;
      }

      .c-form-text-area__name {
        position: relative;
        z-index: 2;
      }
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 13px;
    text-align: right;
  }

  &.--error {
    color: $error-color;

    .c-form-text-area__field {
      border: 1.6px solid $error-color !important;
    }
  }

  &.--disabled {
    .c-form-text-area__name {
      color: rgb(33 40 48 / 24%);
    }

    .c-form-text-area__required {
      color: rgb(33 40 48 / 24%);
    }

    .c-form-text-area__field {
      color: rgb(33 40 48 / 48%);
    }
  }
}
