.c-form-check-box-group {
  position: relative;
  margin-bottom: 20px;
  color: $main-color;

  &__title {
    margin: 0 5px 0 0;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    width: 100%;
    margin-bottom: 5px;

    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    cursor: pointer;

    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    &:checked {
      & ~ .c-form-check-box-group__mark-wrapper {
        .c-form-check-box-group__mark {
          background: #4d5359;
          box-shadow: inset 0 0 0 2px #4d5359;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__mark {
    @include media(
      (
        xsm: (
          top: 1px,
        ),
      )
    );

    position: relative;
    z-index: 1;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    color: #d2d2d2;

    background: transparent;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px #4d5359;

    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &::before {
      content: '';

      display: block;

      width: 100%;
      height: 100%;

      background: url('/svg/components/CheckBox/check.svg?v=1') no-repeat center
        center;
      background-size: 50%;

      transition: opacity 0.1s ease-in-out;
    }

    &-wrapper {
      position: relative;

      display: inline-grid;
      grid-gap: 16px;
      grid-template-columns: 24px 1fr;

      width: 100%;

      text-align: left;
    }
  }

  &__required {
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    color: rgb(52 63 72 / 40%);
  }

  &__label {
    @include media(
      (
        xsm: (
          font-size: 14px,
          line-height: 20px,
        ),
      )
    );

    padding-right: 25px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  &__error {
    display: block;
    font-size: 13px;
    color: $error-color;
    text-align: right;
  }

  &.--error {
    .c-form-check-box-group__mark {
      box-shadow: inset 0 0 0 2px $error-color;
    }
  }
}
